module.exports = {
  Warsaw: {
    displayName: 'Warszawa',
    rooms: {
      kitchen: {
        displayName: 'Przy kuchni',
        desks: [1, 2, 3, 4, 5]
      },
      reception: {
        displayName: 'Przy recepcji',
        desks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      },
      // corner: {
      //   displayName: 'Narożny',
      //   desks: [1, 2, 3, 4, 5, 6, 7, 8]
      // },
      corridor: {
        displayName: 'Na końcu korytarza',
        desks: [1, 2, 3, 4, 5, 6, 7]
      }
    }
  },
  Gdansk: {
    displayName: 'Gdańsk',
    rooms: {
      big: {
        displayName: 'Duży',
        desks: [1, 2, 3, 4, 5, 6, 7, 8, 9]
      },
      medium: {
        displayName: 'Średni',
        desks: [1, 2, 3, 4, 5, 6]
      },
      small: {
        displayName: 'Mały',
        desks: [1, 2]
      }
    }
  }
}
