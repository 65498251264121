const API_SERVER = process.env.REACT_APP_API_SERVER || '../..'

const AUTH_DEPARTMENTS = {
  finance: [44], // Finance
  office: [48], // Office
  dev: [7], // Dev
  hr: [76] // HR
}

const allowedRespDepartments = {
  322: ['Afiliacja', 'Mailing', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Front
  262: ['SEO', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // SEO
  258: ['Content', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Content
  174: ['RTB', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Programmatic
  256: ['Monetyzacja', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // MailSales - E-mail Marketing
  336: ['Analityka', 'Raporty', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Analytics
  290: ['Grafika', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Creative
  354: ['Afiliacja+', 'Mailing', 'Monetyzacja', 'Pozostałe', 'CRM&MA', 'FlatFee', 'SMS/Telemarketing', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Performance
  358: ['Projekty', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // BLIP
  356: ['Afiliacja', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Influency
  338: ['IT', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Traffic
  26: ['PPC', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // PPC - SEM & Social
  7: ['IT', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Development
  48: ['Refaktura', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Office
  442: ['Marketplace', 'Pozostałe', 'Set-up', 'Consulting', 'Audyt', 'Strategia'], // Marketplace
  444: ['Paid Social', 'Set-up', 'Consulting', 'Audyt', 'Strategia'] // Paid Social
}

const allowedFromDepartments = Object.keys(allowedRespDepartments)

const allowedTimeReportsDepartments = [
  322, // Front
  262, // SEO
  258, // SEO Content
  174, // Programmatic
  256, // MailSales - E-mail Marketing
  336, // Analytics
  290, // Creative
  354, // Performance
  // 358, // BLIP
  356, // Influency
  338, // Traffic
  26, // PPC - SEM & Social
  7, // Development
  288, // Strategy
  442, // Marketplace
  444, // Paid Social
  332, // ** Top Manager
  454 // Sales
]

const supportDepartments = [
  86, // Communications & Marketing (skróćmy jako PR) - 86
  44, // Finance - 44
  76, // HR - 76
  350, // IT Support - 350
  48, // Office - 48
  208, // Publishing - 208
  334, // COO - 334
  448, // CSD - 448
  452, // Payroll (id 452)
  1, // Zarząd,
  424, // Data Science
  332 // Top Management
]

// Lista top managerów
//
const topManagersList = [
  '200', // Cezary Śledziak
  '1', // Andrzej Cudny
  '46', // Łukasz Kutyłowski
  '22', // Mateusz Nić
  '144', // Izabela Bogus
  '360', // Agnieszka Kowalczyk
  '536', '168', // seweryn, pawel
  '44', // Joanna Królik
  '32' // Klaudia Hernik
]

const additionalUsers = {
  1: 1, // Andrzej
  46: 44, // Łukasz
  200: 334 // Czarek
}

const sorter = [
  322, // Front
  454, // Sales
  288, // Strategy
  336, // Analytics
  258, // Content
  290, // Creative
  7, // Development
  256, // E-mail Marketing
  442, // Marketplace
  444, // Paid Social
  354, // Performance
  174, // Programmatic
  26, // SEM & Social
  262, // SEO
  338, // Traffic
  332 // Top   Management
]

/** Sprawdza z jakiego działu jest user */

const checkUserType = (user = {}, type) => {
  if (user.REACT_APP_NODE_ENV === 'alpha') {
    AUTH_DEPARTMENTS.finance.push(7) // Dev
  }
  return (user.DEPARTMENT && user.DEPARTMENT.some(el => AUTH_DEPARTMENTS[type].includes(el))) ||
    (user.UF_DEPARTMENT && user.UF_DEPARTMENT.some(el => AUTH_DEPARTMENTS[type].includes(el))) ||
    (user.scDepartment && user.scDepartment.some(el => AUTH_DEPARTMENTS[type].includes(+el))) ||
    user.ID === '298' || false
}

const checkAuthIdUser = (user = {}, ids) => {
  return (user.ID && ids.includes(user.ID)) || false
}

const checkIsB2BUser = (user = {}, month) => {
  return user.monthly && user.monthly[month] && user.monthly[month].b2bReport && (user.monthly[month].b2bReport !== '')
}

const checkIsKMUser = (user = {}, month) => {
  return user.monthly && user.monthly[month] && user.monthly[month].kmReport && (user.monthly[month].kmReport !== '')
}

const checkIsUPUser = (user = {}, month) => {
  return user.monthly && user.monthly[month] && user.monthly[month].upReport && (user.monthly[month].upReport !== '')
}

module.exports = {
  API_SERVER,
  allowedFromDepartments,
  allowedRespDepartments,
  allowedTimeReportsDepartments,
  supportDepartments,
  topManagersList,
  checkUserType,
  checkAuthIdUser,
  additionalUsers,
  checkIsB2BUser,
  checkIsKMUser,
  checkIsUPUser,
  sorter
}
